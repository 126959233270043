export const getRequestedHeader = () => {
  var myHeaders = new Headers();

  myHeaders.append("Authorization", `Bearer ${JSON.parse(localStorage.token)}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return requestOptions;
};
