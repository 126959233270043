import React from "react";
import ContentLoader from "react-content-loader";

const ListTable = (props) => {
  return (
    <ContentLoader viewBox="0 0 400 1000" height={1000} width={400} {...props}>
      <rect x="0" y="0" rx="5" ry="5" width="300" height="1000" />
    </ContentLoader>
  );
};

export default ListTable;
