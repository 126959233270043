import React from "react";
import ContentLoader from "react-content-loader";

const ContentSkeleton = (props) => {
  return (
    <ContentLoader height={200} width={500} {...props}>
      <rect x="0" y="30" rx="0" ry="0" width="400" height="110" />
      <rect x="480" y="30" rx="0" ry="0" width="400" height="110" />
      <rect x="960" y="30" rx="0" ry="0" width="400" height="110" />
      <rect x="1440" y="30" rx="0" ry="0" width="400" height="110" />
    </ContentLoader>
  );
};

export default ContentSkeleton;
