import React from "react";
import { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { AuthContext } from "../App";
import Order from "../components/orders/orders";
import SideBar from "../components/SideBar/sideBar";
import Ticket from "../components/tickets/tickets";
import Home from "../components/home/home";
import LogIn from "../components/utils/logIn";
import ProtectedRoutes from "../auth/protectedRoute";
import Account from "../components/utils/account";

const RouterConfig = () => {
  return (
    <AuthContext.Consumer>
      {({ state }) => (
        <Router>
          <Switch>
            <Route exact path="/login">
              <LogIn />
            </Route>
            <Fragment>
              <SideBar />

              <ProtectedRoutes
                exact
                path="/"
                component={Home}
                isAuth={state.isAuthenticated}
                user={state.user}
              />
              <ProtectedRoutes
                exact
                path="/orders"
                component={Order}
                isAuth={state.isAuthenticated}
                user={state.user}
              />

              <ProtectedRoutes
                exact
                path="/tickets"
                component={Ticket}
                isAuth={state.isAuthenticated}
                user={state.user}
              />

              <ProtectedRoutes
                exact
                path="/account"
                component={Account}
                isAuth={state.isAuthenticated}
                user={state.user}
              />
            </Fragment>
          </Switch>
        </Router>
      )}
    </AuthContext.Consumer>
  );
};

export default RouterConfig;
