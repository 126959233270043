import React, { useEffect, useState, useRef } from "react";
import CalenderConfig from "../utils/calender";
import { Link } from "react-router-dom";
import TicketTableRows from "./ticketTableRows";
import filterIcon from "../../images/filter-icon.svg";
import downloadIcon from "../../images/download-icon.svg";
import { getFilteredOutBounds } from "../../Services/filteredOutBound";
import TableSkeleton from "../utils/tableSkeleton";
import Paginate from "../utils/pagination";
import companyLogo from "../../images/companyLogo.png";
import Select from "react-select";
import { downloadCSVFile } from "../../Services/downloadFile";
import { filterSearchData } from "../utils/filterSearchData";
import moment from "moment";
import Date1 from "../../images/Date.svg";
import DateActive from "../../images/DateActive.svg";
import { getDropDownForTicket } from "../../Services/allDropDown";
import { toast } from "react-hot-toast";

const Ticket = () => {
  const [start, setStart] = useState(
    moment().subtract(29, "days").format("MM/DD/yyyy")
  );
  const [end, setEnd] = useState(moment().format("MM/DD/yyyy"));
  const [startDate, setStartDate] = useState(new Date("09/19/2018"));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [show, setShow] = useState(false);
  const [carrer, setCarrier] = useState(undefined);
  const [ticket, setTicket] = useState(undefined);
  const [customer, setCustomer] = useState(undefined);
  const [terminal, setTerminal] = useState(undefined);
  const [product, setProduct] = useState(undefined);
  const [poNumber, setPoNumber] = useState(undefined);
  const [search, setSearch] = useState("");
  const [result, setResult] = useState([]);
  const [isFilterLoading, setIsFilterLoading] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setoffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [pagesCount, setPagesCount] = useState(5);
  const [reSet, setReSet] = useState(true);
  const [total, setTotal] = useState(0);
  const [isFound, setIsFound] = useState(true);
  const [filterDataDropDown, setFilterDataDropDown] = useState([]);
  const selectCarrierRef = useRef();
  const selectCustomerRef = useRef();
  const selectTerminalRef = useRef();
  const selectProductRef = useRef();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      background: state.isSelected ? "#68a2b9" : "white",
      cursor: "pointer",
      ":hover": {
        background: "#68a2b9",
        color: "white",
      },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      marginLeft: "5px",
    }),
    control: (base, state) => ({
      ...base,
      height: 48,
      minHeight: 48,
      border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
      // This line disable the black border
      boxShadow: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
      "&:hover": {
        border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
      },
    }),
  };

  useEffect(() => {
    const fetchData = async () => {
      setStartDate(
        startDate ? startDate : setStartDate(new Date("09/19/2018"))
      );
      setEndDate(endDate ? endDate : setEndDate(Date.now()));
      try {
        const data = await getFilteredOutBounds(
          moment(startDate).format("yyyy-MM-DD"),
          moment(endDate).format("yyyy-MM-DD"),
          carrer,
          ticket,
          customer,
          terminal,
          product,
          poNumber,
          offset,
          limit
        );

        setPagesCount(data.pages);
        setTotal(data.totalDocuments);
        setResult(data.data.docs);
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };

    fetchData();
    const fetchFilteredData = async () => {
      try {
        const { data } = await getDropDownForTicket();

        setFilterDataDropDown(data);
      } catch (err) {
        console.log(err);
      }
      setIsFilterLoading(false);
    };

    fetchFilteredData();
  }, [isFilter, offset, limit, reSet]);

  const FilterSearchData = (data) => {
    if (data === "") {
      setIsFound(true);
      setReSet(!reSet);
    } else {
      let filtered = filterSearchData(data, result);

      if (filtered) {
        setIsFound(true);
        setResult(filtered);
      } else {
        setIsFound(false);
      }
    }
  };

  const downloadCSV = async () => {
    toast.promise(
      downloadCSVFile(
        total,
        offset,
        search === "" ? undefined : search,
        poNumber,
        ticket,
        moment(startDate).format("yyyy-MM-DD"),
        moment(endDate).format("yyyy-MM-DD"),
        customer,
        terminal,
        product,
        "Closed",
        carrer,
        "Outbound_Tickets"
      ),
      {
        loading: "Downloading...",
        success: "Download Success",
        error: "Download Failed",
      }
    );
  };

  return (
    <div>
      <div>
        <main className="content">
          <div className="container-fluid">
            <div className="row g-0 title-row">
              <div className="col-md-6">
                <h1 className="mb-0">Tickets</h1>
              </div>
              <div className="col-md-6 text-end"></div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="filter-row">
                  <div className="dropdown">
                    <button
                      className="btn btn-light"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => {
                        setShow(!show);
                      }}
                    >
                      <img src={filterIcon} alt="" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link className="dropdown-item" to="#">
                          Action
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Another action
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Something else here
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      downloadCSV();
                    }}
                  >
                    <img src={downloadIcon} alt="" /> Download Transactions
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card card-table">
                  <div className="card-head">
                    <div className="row g-0">
                      <div className="col-6 col-md-10">
                        <h3>Outbound Tickets</h3>
                      </div>
                      <div className="col-6 col-md-2">
                        <form>
                          <input
                            type="text"
                            className="form-control  search-ticket form-control"
                            onChange={(e) => {
                              FilterSearchData(e.target.value);
                              setSearch(e.target.value);
                            }}
                            placeholder="Search"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body table-section">
                    {!isLoading ? (
                      <table className="table table-style">
                        <thead>
                          <tr>
                            <th scope="col">Terminal</th>
                            <th scope="col">Ticket #</th>
                            <th scope="col">Closed Date</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Carrier</th>
                            <th scope="col">Driver</th>
                            <th scope="col">PO#</th>
                            <th scope="col">Product</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isFound ? (
                            result.map((ticket, index) => {
                              return (
                                <TicketTableRows
                                  ticketData={ticket}
                                  imageSrc={downloadIcon}
                                  index={index}
                                  key={index}
                                />
                              );
                            })
                          ) : (
                            <span></span>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <TableSkeleton />
                    )}
                  </div>

                  <Paginate
                    limit={limit}
                    offset={offset}
                    setoffset={setoffset}
                    perPage={limit}
                    pageCount={pagesCount}
                    setLimit={setLimit}
                    total={total}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            <div className="powerby">
              <span style={{ fontSize: 14 }}>Powered by</span>
              <img
                src={companyLogo}
                alt="company logo"
                style={{ width: "123px", height: "16px", marginLeft: "1%" }}
              />
            </div>
          </div>
        </main>

        <div
          className={`filter-canvas ${show ? "is-open" : "is-close"}`}
          style={!show ? { display: "none" } : { display: "block" }}
        >
          <form>
            <div className="head">
              <h3>Filters</h3>
              <button
                type="button"
                onClick={() => {
                  setShow(!show);
                }}
                className="btn btn-close btn-link"
              ></button>
            </div>
            <div className="body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="daterange" className="form-label">
                      Closed Date Range
                    </label>
                    <div style={{ position: "relative" }}>
                      <CalenderConfig
                        start1={start}
                        end1={end}
                        setStart={setStart}
                        setEnd={setEnd}
                        startDate={setStartDate}
                        endDate={setEndDate}
                      />
                      <img
                        className="custom-img-class"
                        src={start === "" && end === "" ? Date1 : DateActive}
                        alt="date img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="carrier" className="form-label">
                      Carrier
                    </label>
                    {!isFilterLoading ? (
                      <Select
                        ref={selectCarrierRef}
                        className=""
                        styles={customStyles}
                        isClearable={true}
                        onChange={(e) => {
                          setCarrier(e ? e.label : "");
                        }}
                        options={filterDataDropDown.carriers.map(
                          (key, text) => {
                            return {
                              value: text,
                              label: key.Hauler,
                            };
                          }
                        )}
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="ticket" className="form-label">
                      Ticket
                    </label>
                    <input
                      className="form-control filter-canvas-input"
                      type="text"
                      onChange={(e) => {
                        setTicket(e.target.value);
                      }}
                      id="ticket"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="customer" className="form-label">
                      Customer
                    </label>
                    {!isFilterLoading ? (
                      <Select
                        className="filter-select-box"
                        ref={selectCustomerRef}
                        styles={customStyles}
                        isClearable={true}
                        onChange={(e) => {
                          setCustomer(e ? e.label : "");
                        }}
                        options={filterDataDropDown.customers.map(
                          (key, text) => {
                            return {
                              value: text,
                              label: key.CustomerName,
                            };
                          }
                        )}
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="terminal" className="form-label">
                      Terminal
                    </label>
                    {!isFilterLoading ? (
                      <Select
                        className="filter-select-box"
                        ref={selectTerminalRef}
                        styles={customStyles}
                        isClearable={true}
                        onChange={(e) => {
                          setTerminal(e ? e.label : "");
                        }}
                        options={filterDataDropDown.terminals.map(
                          (key, text) => {
                            return {
                              value: text,
                              label: key.TerminalName,
                            };
                          }
                        )}
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="product" className="form-label">
                      Product
                    </label>
                    {!isFilterLoading ? (
                      <Select
                        className="filter-select-box"
                        ref={selectProductRef}
                        styles={customStyles}
                        isClearable={true}
                        onChange={(e) => {
                          setProduct(e ? e.label : "");
                        }}
                        options={filterDataDropDown.products.map(
                          (key, text) => {
                            return {
                              value: text,
                              label: key.Product,
                            };
                          }
                        )}
                      />
                    ) : (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="po" className="form-label">
                      PO#
                    </label>
                    <input
                      className="form-control filter-canvas-input"
                      type="text"
                      onChange={(e) => {
                        setPoNumber(e.target.value);
                      }}
                      id="po"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="foot">
              <div className="row g-0">
                <div className="col">
                  <button
                    type="reset"
                    onClick={() => {
                      selectCarrierRef.current.select.clearValue();
                      selectCustomerRef.current.select.clearValue();
                      selectTerminalRef.current.select.clearValue();
                      selectProductRef.current.select.clearValue();
                      setStartDate(new Date("09/19/2018"));
                      setEndDate(new Date(Date.now()));
                      setStart("");
                      setEnd("");
                      setCarrier("");
                      setTerminal("");
                      setTicket("");
                      setCustomer("");
                      setProduct("");
                      setPoNumber("");
                      setIsFilter(!isFilter);
                    }}
                    className="btn btn-clear btn-link"
                  >
                    Clear
                  </button>
                </div>
                <div className="col text-end">
                  <button
                    type="button"
                    onClick={() => {
                      setIsFilter(!isFilter);
                    }}
                    className="btn btn-primary"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
