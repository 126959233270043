import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getFilteredOrders = async (
  terminal,
  customer,
  product,
  po,
  status,
  limit,
  offset
) => {
  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/get-all-purchased-orders-with-filter?${
      terminal ? "terminal=" + terminal : ""
    }&${customer ? "customer=" + customer : ""}&${
      product ? "product=" + product : ""
    }&${po ? "poNumber=" + po : ""}&${status ? "poStatus=" + status : ""}&${
      limit ? "limit=" + limit : ""
    }&${offset ? "offset=" + offset : ""}`,
    getRequestedHeader()
  );

  const result = await response.json();

  return result;
};
