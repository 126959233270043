import React from "react";
import toast from "react-hot-toast";

import { downloadTicketImage } from "../../Services/downloadFile";

const OrderTableRows = ({ poData, imageSrc, index }) => {
  const downloadImage = async () => {
    const data = await downloadTicketImage(poData.id);

    window.open(URL.createObjectURL(data));
  };

  return (
    <tr>
      <th scope="row">{poData.TicketNumber}</th>
      <td>{poData.Hauler}</td>
      <td>{poData.Driver}</td>
      <td>{poData.truckid}</td>
      <td>{poData.closedon ? poData.closedon : ""}</td>
      <td>
        {poData.NetWeight
          ? parseInt(poData.NetWeight)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : 0}
      </td>
      <td>{poData.PONumber}</td>
      <td className="po-download">
        <button
          type="button"
          onClick={() => {
            toast.promise(downloadImage(), {
              loading: "Downloading...",
              success: "Downloaded",
              error: "Error downloading! File not found.",
            });
          }}
          className="btn btn-link"
        >
          <img src={imageSrc} alt="" />
        </button>
      </td>
    </tr>
  );
};

export default OrderTableRows;
