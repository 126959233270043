import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getFilteredOutBounds = async (
  startDate = undefined,
  endDate = undefined,
  carrier = undefined,
  ticket = undefined,
  customer = undefined,
  terminal = undefined,
  product = undefined,
  po = undefined,
  offset,
  limit = 20
) => {
  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/get-outbound-tickets?${
      carrier ? "carrier=" + carrier : ""
    }&${product ? "product=" + product : ""}&${
      terminal ? "terminal=" + terminal : ""
    }&${startDate ? "startDate=" + startDate : ""}&${
      endDate ? "endDate=" + endDate : ""
    }&${ticket ? "ticketNumber=" + ticket : ""}&${
      customer ? "customer=" + customer : ""
    }&${po ? "poNumber=" + po : ""}&${offset ? "offset=" + offset : ""}&${
      limit ? "limit=" + limit : ""
    }`,
    getRequestedHeader()
  );

  const result = await response.json();

  return result;
};
