import React from "react";
import Select from "react-select";

const customStyles = {
  option: (provided, state) => ({
    ...provided,

    background: state.isSelected ? "#68a2b9" : "white",
    cursor: "pointer",
    ":hover": {
      background: "#68a2b9",
      color: "white",
    },
  }),
  // control: (styles) => ({
  //   ...styles,
  //   height: 48,
  //   minHeight: 48,

  //   ":focus": {
  //     borderColor: "#68a2b9",
  //     border: "2px solid #68a2b9",
  //   },
  //   ":active": {
  //     borderColor: "#68a2b9",
  //     border: "2px solid #68a2b9",
  //   },
  // }),
  control: (base, state) => ({
    ...base,
    height: 48,
    minHeight: 48,
    border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    // This line disable the black border
    boxShadow: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    "&:hover": {
      border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    },
  }),
};

const Limitation = ({ setLimit, setoffset, setCurrentPage, limit }) => {
  const options = [
    { value: "10", label: "10 rows" },
    { value: "20", label: "20 rows" },
    { value: "50", label: "50 rows" },
    { value: "100", label: "100 rows" },
  ];

  return (
    <>
      <span style={{ display: "table-cell", width: "53px", color: "#58627A" }}>
        Show
      </span>

      <Select
        className="pagination-select"
        value={options.value}
        isSearchable={false}
        styles={customStyles}
        onChange={(e) => {
          setLimit(e.value);
          setoffset(0);
          setCurrentPage(0);
        }}
        options={options}
        defaultValue={options[2]}
      />
    </>
  );
};

export default Limitation;
