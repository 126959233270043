const checkIsNull = (data, obj = undefined) => {
  if (!data) return "";
  else {
    return data.toString().toLowerCase();
  }
};

export const filterSearchData = (data, poFilteredData) => {
  let filtered = poFilteredData.filter(
    (obj) =>
      checkIsNull(obj.CustomerName).includes(data.toLowerCase()) ||
      checkIsNull(obj.Driver).includes(data.toLowerCase()) ||
      checkIsNull(obj.TerminalName).includes(data.toLowerCase()) ||
      checkIsNull(obj.TicketNumber).includes(data.toLowerCase()) ||
      checkIsNull(obj.Product).includes(data.toLowerCase()) ||
      checkIsNull(obj.PONumber).includes(data.toLowerCase()) ||
      checkIsNull(obj.Hauler).includes(data.toLowerCase())
  );

  if (filtered.length > 0) {
    return filtered;
  }
};
