import React from "react";
import g from "../../../images/g.svg";
import gActive from "../../../images/gActive.svg";

const OrdersList = ({
  poNumber,
  productType,
  poStatus,
  setActivePo,
  currentPO,
  activePO,
  fetchPOData,
}) => {
  return (
    <li
      onClick={() => {
        setActivePo(currentPO);
        fetchPOData(currentPO);
      }}
      className={`list-item ${
        activePO ? (activePO.PONumber === poNumber ? "active" : "") : ""
      }`}
    >
      <div className="list-img">
        {/* <i className="bi bi-card-list"></i> */}
        <img
          src={activePO && activePO.PONumber === poNumber ? gActive : g}
          alt="img"
        />
        <span
          className="dot"
          style={{
            backgroundColor:
              poStatus === "Open"
                ? "#0080E8"
                : poStatus === "Closed"
                ? "#F23217"
                : "#21AF5F",
          }}
        ></span>
      </div>
      <div className="list-text">
        <h3 className="custom-dotted">{poNumber}</h3>
        <p>{productType}</p>
      </div>
      <div className="list-menu dropdown">
        {/* <button
          className="btn btn-link"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-three-dots-vertical"></i>
        </button> */}
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
          style={{ display: "none" }}
        >
          <li>Action</li>
        </ul>
      </div>
    </li>
  );
};

export default OrdersList;
