import React from "react";
import { Route, Redirect } from "react-router";
import TagManager from "react-gtm-module";

const ProtectedRoutes = ({ isAuth, user, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          TagManager.dataLayer({
            dataLayer: {
              user_id: user.email.split("@")[0],
            },
          });

          return <Component />;
        } else {
          return <Redirect to={{ pathname: "/login" }} />;
        }
      }}
    />
  );
};

export default ProtectedRoutes;
