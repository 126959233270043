import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getOrdersOverView = async (choice) => {
  const options = getRequestedHeader();

  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/order-overview?${
      choice ? "unit=" + choice : ""
    }`,
    options
  );
  const result = await response.json();
  return result;
};
