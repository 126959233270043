import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getSummary = async () => {
  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/get-summary`,
    getRequestedHeader()
  );

  const result = await response.json();

  return result;
};
