import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getAllTerminals = async () => {
  const options = getRequestedHeader();

  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/order-overview-terminals`,
    options
  );

  const result = await response.json();

  return result;
};
