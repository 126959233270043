import React from "react";
import ContentLoader from "react-content-loader";
const TableSkeleton = (props) => {
  return (
    <ContentLoader viewBox="0 0 400 600" {...props}>
      <rect x="0" y="0" rx="2" ry="2" width="800" height="500" />
    </ContentLoader>
  );
};

export default TableSkeleton;
