import { createContext, useReducer } from "react";
import { Toaster } from "react-hot-toast";
import TagManager from "react-gtm-module";

import "./App.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import RouterConfig from "./routes/routes";

export const AuthContext = createContext(undefined);

const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  isAuthenticated: user && token ? true : false,
  user: user,
  token: token,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        isAuthenticated: true,
        user: action.payload.data,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const logIn = (user) => {
    localStorage.setItem("user", JSON.stringify(user.data));
    localStorage.setItem("token", JSON.stringify(user.token));

    dispatch({
      type: "LOGIN",
      payload: user,
    });
  };

  const logout = () => {
    localStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
  };

  TagManager.initialize({
    gtmId: "G-SVJW4G132H",
    dataLayer: {
      platform: "react",
    },
  });

  return (
    <div className="App">
      <AuthContext.Provider value={{ state, logIn, logout }}>
        <RouterConfig />
      </AuthContext.Provider>
      <Toaster />
    </div>
  );
}

export default App;
