import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
const DoughnutChart = ({ userData }) => {
  let loaded = userData[0] - userData[1];
  let percentage = (loaded / userData[0]) * 100;

  if (!percentage) {
    percentage = 0;
  }
  percentage = parseFloat(percentage).toFixed(2);
  return (
    <CircularProgressbar
      className="my-chart"
      value={percentage}
      text={`${percentage}%`}
    />
  );
};

export default DoughnutChart;
