import React from "react";
import toast from "react-hot-toast";

import { downloadTicketImage } from "../../Services/downloadFile";

const TicketTableRows = ({ ticketData, imageSrc, index }) => {
  const downloadImage = async () => {
    const { id: ticketNumber } = ticketData;

    const data = await downloadTicketImage(ticketNumber);

    window.open(URL.createObjectURL(data));
  };

  return (
    <tr>
      <th scope="row">{ticketData.TerminalName}</th>
      <td>{ticketData.TicketNumber}</td>
      <td>{ticketData.closedon}</td>
      <td>{ticketData.CustomerName}</td>
      <td>{ticketData.Hauler}</td>
      <td>{ticketData.Driver}</td>
      <td>{ticketData.PONumber}</td>
      <td>{ticketData.Product}</td>
      <td>
        {!parseInt(ticketData.NetWeight)
          ? 0
          : parseInt(ticketData.NetWeight).toLocaleString()}
      </td>
      <td>{ticketData.TicketStatus}</td>
      <td className="ticket-download">
        <button
          type="button"
          onClick={() => {
            toast.promise(downloadImage(), {
              loading: "Downloading...",
              success: "Downloaded",
              error: "Error downloading! File not found.",
            });
          }}
          className="btn btn-link"
        >
          <img src={imageSrc} alt="" />
        </button>
      </td>
    </tr>
  );
};

export default TicketTableRows;
