import React from "react";

import styled from "styled-components";
import { useTable, useSortBy } from "react-table";

const TestTable = ({ orders, dataForm, unit }) => {
  let data = orders.data.todaySummary;

  let formateddata = data.map((element) => {
    element.LBSLoaded = element?.LBSLoaded.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    element.LBSMTDLoaded = element?.LBSMTDLoaded.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    element.LBSPOAmount = element?.LBSPOAmount.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    element.LBSRemaining = element?.LBSRemaining.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    element.TONSLoaded = element?.TONSLoaded.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    element.TONSMTDLoaded = element?.TONSMTDLoaded.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    element.TONSPOAmount = element?.TONSPOAmount.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    element.TONSRemaining = element?.TONSRemaining.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    if (!element.SiteName) {
      element.SiteName = "-";
    }

    return element;
  });

  const Styles = styled.div`
  padding:0px;
   margin-botton:14px;
    table {
      border-spacing: 0;
      border: none;
      width:100%;

      tr {
        :nth-child(1){
          td:nth-child(1) {
            border-left: 0 !important;
            color: #232A39;
            font-weight: 600;
          }
        }
      }

      .terminal-column {
        border-left: 0 !important;
        color: #232A39;
        font-weight: 600;
      }

      th {
        margin: 0;
        padding: 0.5rem;
       
        background-color: #f7f9fc;
    box-shadow: inset 0 -1px 0 0 #eceff7;
    font-size: 14px;
    color: #8a93a7;
    line-height: 16px;
    font-weight: normal;
    padding: 12px 12px;
    vertical-align: middle;
    box-shadow: inset 0 -1px 0 0 #eceff7;
    border: none;
   

        :last-child {
          border-right: 0;
        }
      }
      td {
  
        font-size: 14px;
        color: #232a39;
     
        vertical-align: middle;
        line-height: 16px;
        padding: 19px 12px;
        font-size: 14px;
        color: #58627a;
        box-shadow: inset 0 -1px 0 0 #eceff7;
        border: none;
        border-left: 1px solid #eceff7;


        
    }
        :last-child {
          border-right: 0;
        }
       
      }
    }
  `;

  // hook for adding rowspan
  function useRowSpan(instance) {
    const { allColumns } = instance;

    allColumns.forEach((column) => {
      if (column.enableRowSpan) {
        column.topCellValue = null;
        column.topCellIndex = 0;
      }
    });
  }

  function TableRow({ row }) {
    let cells = [];
    let len = row.cells.length;
    for (let i = 0; i < len; i++) {
      let cell = row.cells[i];
      if (!cell.isRowSpanned) {
        cells.push(
          <td
            className={
              cell.column.id === "TerminalName" ? "terminal-column" : ""
            }
            rowSpan={cell.rowSpan}
            key={`${cell.column.id}`}
          >
            {cell.render("Cell")}
          </td>
        );
      }
    }
    return (
      <tr {...row.getRowProps()} key={`${row.original.id}`}>
        {cells}
      </tr>
    );
  }

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
        },
        useSortBy,
        (hooks) => {
          hooks.useInstance.push(useRowSpan);
        }
      );

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 20).map((row, i) => {
      prepareRow(row);
      let numCells = row.allCells.length;

      /* keep track of whether a cell to the left had a unique value, and propagate that across the row.
       * this is to prevent spanning children cells across different parents, for hierarchical data.
       * This feature is disabled - just uncomment the lines containing parentBoundary to enable.
       */
      //let parentBoundary = false;

      // "Merge Cells" functionality - combine equal cells that are next to each-other.
      for (let j = 0; j < numCells; j++) {
        let cell = row.allCells[j];
        let column = cell.column;

        if (column.enableRowSpan) {
          // rowspan is enabled for this column
          if (
            column.topCellValue !== cell.value || // we have a non-duplicate cell
            cell.value === "" || // or we have a blank cell
            //parentBoundary ||  // or we crossed a parent boundary on this row already (only for hierarchical data)
            column.topCellValue === null // or we are on the first row
          ) {
            // cell should not be rowspanned.
            column.topCellValue = cell.value;
            //parentBoundary = true; // set parent boundary flag (only for hierarchical data)
            column.topCellIndex = i;
            cell.spannedRows = [row];
            cell.rowSpan = 1;
            cell.isRowSpanned = false; // render this cell
          } else {
            // cell is rowspanned.
            cell.isRowSpanned = true; // skip rendering this cell
            // update the top cell
            rows[column.topCellIndex].allCells[j].rowSpan++;
            rows[column.topCellIndex].allCells[j].spannedRows.push(row); // spannedRows can be used in the cell renderer to do cool stuff.
          }
        }
      }
      // else: rowspan not enabled for this column, no extra actions required.

      // Return the actual row component
      return <TableRow key={row.original.id} row={row} />;
    });

    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i class="bi bi-caret-down"></i>
                        ) : (
                          <i class="bi bi-caret-up"></i>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>{firstPageRows}</tbody>
        </table>
      </>
    );
  }

  const columns = React.useMemo(() => [
    {
      Header: "Terminal",
      accessor: "TerminalName",
      enableRowSpan: true,
    },
    {
      Header: "Product Type",
      accessor: "ProductType",
      enableRowSpan: true,
    },
    {
      Header: "Purchase Order",
      accessor: `PONumber`,
    },
    {
      Header: "Site",
      accessor: "SiteName",
    },
    {
      Header: "PO Amount",
      accessor: `${unit}POAmount`,
    },
    {
      Header: "Loaded",
      accessor: `${unit}Loaded`,
    },
    {
      Header: "MTD Loaded",
      accessor: `${unit}MTDLoaded`,
    },

    {
      Header: "Remaining",
      accessor: `${unit}Remaining`,
    },
  ]);

  return (
    <div
      className="home-page-table"
      style={{ width: "100%", marginTop: "10px" }}
    >
      <Styles>
        <Table columns={columns} data={formateddata} />
      </Styles>
    </div>
  );
};

export default TestTable;
