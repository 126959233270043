import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import logo from "../../images/Logo.png";
import logout from "../../images/logOut.png";
import deleteImg from "../../images/delete.svg";
import accountImg from "../../images/User.svg";
import avatar from "../../images/avatar.jpg"
import { AuthContext } from "../../App";

const SideBar = () => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState("Home");

  const handleClick = (value) => {
    setActive(value);
  };

  let svgData = [
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        <g
          className="svg-icon"
          transform="translate(3.000000, 3.000000)"
          fill="#8A99A3"
          stroke="#8A99A3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M5.99707067,9 C6.56211865,9 7,9.44661595 7,9.99754465 L7,9.99754465 L7,17.0024554 C7,17.5536886 6.55097324,18 5.99707067,18 L5.99707067,18 L1.00292933,18 C0.437881351,18 0,17.553384 0,17.0024554 L0,17.0024554 L0,9.99754465 C0,9.4463114 0.449026756,9 1.00292933,9 L1.00292933,9 Z M16.9970707,13 C17.5621186,13 18,13.4476291 18,13.9998075 L18,13.9998075 L18,17.0001925 C18,17.5562834 17.5509732,18 16.9970707,18 L16.9970707,18 L12.0029293,18 C11.4378814,18 11,17.5523709 11,17.0001925 L11,17.0001925 L11,13.9998075 C11,13.4437166 11.4490268,13 12.0029293,13 L12.0029293,13 Z M16.9970707,0 C17.5621186,0 18,0.446615951 18,0.997544646 L18,0.997544646 L18,8.00245535 C18,8.5536886 17.5509732,9 16.9970707,9 L16.9970707,9 L12.0029293,9 C11.4378814,9 11,8.55338405 11,8.00245535 L11,8.00245535 L11,0.997544646 C11,0.446311399 11.4490268,0 12.0029293,0 L12.0029293,0 Z M5.99707067,0 C6.56211865,0 7,0.447629061 7,0.999807492 L7,0.999807492 L7,4.00019251 C7,4.55628335 6.55097324,5 5.99707067,5 L5.99707067,5 L1.00292933,5 C0.437881351,5 0,4.55237094 0,4.00019251 L0,4.00019251 L0,0.999807492 C0,0.443716645 0.449026756,0 1.00292933,0 L1.00292933,0 Z"></path>
        </g>
      </g>
    </svg>,
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect
          id="Rectangle"
          opacity="0.100000001"
          x="0"
          y="0"
          width="24"
          height="24"
        ></rect>
        <g
          className="svg-icon-2"
          transform="translate(5.000000, 2.000000)"
          stroke="#8A99A3"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path
            d="M7,2 C8.30590781,2 9.41692869,1.16558987 9.82899999,0.000832261365 L13,0 C13.5522847,-1.01453063e-16 14,0.44771525 14,1 L14,19 C14,19.5522847 13.5522847,20 13,20 L9.82932572,20.0000889 C9.41751442,18.8348501 8.30625206,18 7,18 C5.69374794,18 4.58248558,18.8348501 4.17067428,20.0000889 L1,20 C0.44771525,20 6.76353751e-17,19.5522847 0,19 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L4.17100001,0.000832261365 C4.58307131,1.16558987 5.69409219,2 7,2 Z"
            strokeLinecap="round"
          ></path>
          <line
            x1="14"
            y1="7"
            x2="0"
            y2="7"
            id="Path"
            strokeDasharray="2"
          ></line>
        </g>
      </g>
    </svg>,
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        <g
          className="svg-icon-2"
          transform="translate(4.000000, 2.000000)"
          stroke="#8A99A3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path
            d="M15,2 C15.5522847,2 16,2.44771525 16,3 L16,19 C16,19.5522847 15.5522847,20 15,20 L1,20 C0.44771525,20 6.76353751e-17,19.5522847 0,19 L0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 L6,2 L6,2.25 C6,3.3043618 6.81587779,4.16816512 7.85073766,4.24451426 L8,4.25 C9.1045695,4.25 10,3.3545695 10,2.25 L10,2.25 L10,2 L15,2 Z"
            id="Combined-Shape"
          ></path>
          <rect id="Rectangle" x="6" y="0" width="4" height="4" rx="2"></rect>
          <line x1="12" y1="11.5" x2="4" y2="11.5" id="Path-Copy-3"></line>
          <line x1="7" y1="15" x2="4" y2="15" id="Path-Copy-4"></line>
          <line x1="12" y1="8" x2="4" y2="8" id="Path"></line>
        </g>
      </g>
    </svg>,
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Orders</title>
      <g
        id="Web-Design"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="HC-PO---transaction"
          transform="translate(-18.000000, -168.000000)"
        >
          <g id="ic_PO-Tracker/on" transform="translate(0.000000, 156.000000)">
            <g transform="translate(18.000000, 12.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g
                id="Group-34"
                transform="translate(4.000000, 2.000000)"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              >
                <path
                  d="M15,2 C15.5522847,2 16,2.44771525 16,3 L16,19 C16,19.5522847 15.5522847,20 15,20 L1,20 C0.44771525,20 6.76353751e-17,19.5522847 0,19 L0,3 C-6.76353751e-17,2.44771525 0.44771525,2 1,2 L6,2 L6,2.25 C6,3.3043618 6.81587779,4.16816512 7.85073766,4.24451426 L8,4.25 C9.1045695,4.25 10,3.3545695 10,2.25 L10,2.25 L10,2 L15,2 Z"
                  id="Combined-Shape"
                  stroke="#68A2B9"
                  fill="#68A2B9"
                ></path>
                <rect
                  id="Rectangle"
                  stroke="#68A2B9"
                  x="6"
                  y="0"
                  width="4"
                  height="4"
                  rx="2"
                ></rect>
                <line
                  x1="12"
                  y1="11.5"
                  x2="4"
                  y2="11.5"
                  id="Path-Copy-3"
                  stroke="#131920"
                ></line>
                <line
                  x1="7"
                  y1="15"
                  x2="4"
                  y2="15"
                  id="Path-Copy-4"
                  stroke="#131920"
                ></line>
                <line
                  x1="12"
                  y1="8"
                  x2="4"
                  y2="8"
                  id="Path"
                  stroke="#131920"
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>,
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Tickets</title>
      <g
        id="Web-Design"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="HC-Tickets-search"
          transform="translate(-18.000000, -120.000000)"
        >
          <g id="ic_jobs/off" transform="translate(0.000000, 108.000000)">
            <g transform="translate(18.000000, 12.000000)">
              <rect
                id="Rectangle"
                opacity="0.100000001"
                x="0"
                y="0"
                width="24"
                height="24"
              ></rect>
              <g id="Group-6" transform="translate(5.000000, 2.000000)">
                <path
                  d="M7,2 C8.30590781,2 9.41692869,1.16558987 9.82899999,0.000832261365 L13,0 C13.5522847,-1.01453063e-16 14,0.44771525 14,1 L14,19 C14,19.5522847 13.5522847,20 13,20 L9.82932572,20.0000889 C9.41751442,18.8348501 8.30625206,18 7,18 C5.69374794,18 4.58248558,18.8348501 4.17067428,20.0000889 L1,20 C0.44771525,20 6.76353751e-17,19.5522847 0,19 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L4.17100001,0.000832261365 C4.58307131,1.16558987 5.69409219,2 7,2 Z"
                  id="Combined-Shape"
                  stroke="#68A2B9"
                  stroke-width="1.5"
                  fill="#68A2B9"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M14,6.25 L12,6.25 L12,7.75 L14,7.75 L14,6.25 Z M10,6.25 L8,6.25 L8,7.75 L10,7.75 L10,6.25 Z M6,6.25 L4,6.25 L4,7.75 L6,7.75 L6,6.25 Z M2,6.25 L0,6.25 L0,7.75 L2,7.75 L2,6.25 Z"
                  id="Path"
                  fill="#131920"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>,
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Home</title>
      <g
        id="Web-Design"
        stroke="none"
        stroke-width="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="HC-Tickets" transform="translate(-18.000000, -72.000000)">
          <g id="Dashboard/off" transform="translate(0.000000, 60.000000)">
            <g
              id="ic_dashboard/off"
              transform="translate(18.000000, 12.000000)"
            >
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <g
                id="Group-33"
                transform="translate(3.000000, 3.000000)"
                stroke="#8A99A3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              >
                <path
                  d="M5.99707067,18 C6.55097324,18 7,17.5536886 7,17.0024554 L7,9.99754465 C7,9.44661595 6.56211865,9 5.99707067,9 L1.00292933,9 C0.449026756,9 0,9.4463114 0,9.99754465 L0,17.0024554 C0,17.553384 0.437881351,18 1.00292933,18 L5.99707067,18 Z"
                  id="Path"
                ></path>
                <path
                  d="M16.9970707,18 C17.5509732,18 18,17.5562834 18,17.0001925 L18,13.9998075 C18,13.4476291 17.5621186,13 16.9970707,13 L12.0029293,13 C11.4490268,13 11,13.4437166 11,13.9998075 L11,17.0001925 C11,17.5523709 11.4378814,18 12.0029293,18 L16.9970707,18 Z"
                  id="Path"
                ></path>
                <path
                  d="M11,8.00245535 C11,8.55338405 11.4378814,9 12.0029293,9 L16.9970707,9 C17.5509732,9 18,8.5536886 18,8.00245535 L18,0.997544646 C18,0.446615951 17.5621186,0 16.9970707,0 L12.0029293,0 C11.4490268,0 11,0.446311399 11,0.997544646 L11,8.00245535 Z"
                  id="Path"
                ></path>
                <path
                  d="M5.99707067,5 C6.55097324,5 7,4.55628335 7,4.00019251 L7,0.999807492 C7,0.447629061 6.56211865,0 5.99707067,0 L1.00292933,0 C0.449026756,0 0,0.443716645 0,0.999807492 L0,4.00019251 C0,4.55237094 0.437881351,5 1.00292933,5 L5.99707067,5 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>,
  ];

  return (
    <div>
      <aside className="main-sidebar">
        <Link
          exact
          to="/"
          className="sidebar-brand"
          title="Icon-only"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          onClick={() => {
            handleClick("Home");
          }}
        >
          <img src={logo} alt="" />
        </Link>
        <div className="sidebar-inner">
          <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
            <li className="nav-item">
              <Link
                to="/"
                exact
                className="nav-link"
                aria-current="page"
                title="Home"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                onClick={() => {
                  handleClick("Home");
                }}
              >
                {active === "Home" ? svgData[0] : svgData[5]}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/tickets"
                className="nav-link "
                title="Tickets"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                onClick={() => {
                  handleClick("Ticket");
                }}
              >
                {active === "Ticket" ? svgData[4] : svgData[1]}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/orders"
                className="nav-link"
                title="Orders"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                onClick={() => {
                  handleClick("Order");
                }}
              >
                {active === "Order" ? svgData[3] : svgData[2]}
              </Link>
            </li>
          </ul>
          <div className="bottom-menu custom-focus">
            <ul className="nav">
              <li className="nav-item dropdown">
                <div
                  onClick={() => {
                    setShow(false);
                  }}
                  className={show ? "custom-overlay" : ""}
                ></div>
                <div
                  className="rectangle"
                  style={
                    !show
                      ? { display: "none" }
                      : { display: "block", zIndex: 9 }
                  }
                >
                  {/* <button
                    className="btn"
                    onClick={() => {
                      handleClick("Account");
                      window.location = "/account";
                    }}
                  >
                    <img src={accountImg} alt="account" />
                    <span>&nbsp;&nbsp; Account</span>
                  </button> */}
                  <Link
                    to="/account"
                    className=" btn"
                    title="Account"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    onClick={() => {
                      handleClick("Account");
                    }}
                  >
                    <img
                      style={{ display: "inline", height: 18 }}
                      src={accountImg}
                      alt="account"
                    />
                    <span>&nbsp;&nbsp; Account</span>
                  </Link>
                  <button
                    className="btn"
                    onClick={() => {
                      window.open(
                        "http://appsupport.hicrush.com/support/home",
                        "_blank"
                      );
                    }}
                  >
                    <img src={deleteImg} alt="help" />
                    <span>&nbsp;&nbsp; Help</span>
                  </button>
                  <button
                    className="btn"
                    onClick={() => {
                      window.localStorage.clear();
                      window.location = "/login";
                    }}
                  >
                    <img src={logout} alt="logout" />
                    <span className="log-out">&nbsp;&nbsp; Log Out</span>
                  </button>
                </div>
                <span
                  className="nav-link"
                  id="dropdownUser3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <img
                    src={avatar}
                    alt="mdo"
                    width="24"
                    height="24"
                    className="rounded-circle"
                  />
                </span>

                <ul
                  className="dropdown-menu text-small shadow"
                  aria-labelledby="dropdownUser3"
                >
                  <li>
                    <Link className="dropdown-item" to="#">
                      New project...
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Profile
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <AuthContext.Consumer>
                      {({ logout }) => {
                        return (
                          <Link
                            onClick={logout}
                            className="dropdown-item"
                            to="login"
                          >
                            Sign out
                          </Link>
                        );
                      }}
                    </AuthContext.Consumer>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default SideBar;
