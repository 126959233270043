import React from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

const CalenderConfig = ({
  start1,
  setStart,
  end1,
  setEnd,
  startDate,
  endDate,
}) => {
  function handleEvent(event, picker) {}
  function handleCallback(start, end, label) {
    startDate(start._d);
    endDate(end._d);
    setStart(moment(start._d).format("MM/DD/yyyy"));
    setEnd(moment(end._d).format("MM/DD/yyyy"));
  }
  return (
    <div>
      <DateRangePicker
        initialSettings={{
          startDate: start1,
          endDate: end1,
          alwaysShowCalendars: true,
          autoApply: true,
          onFocusChange: {},

          ranges: {
            "All time": ["01/01/2018", moment()],
            Today: [moment().toDate(), moment().toDate()],
            "Last 7 Days": [
              moment().subtract(6, "days").toDate(),
              moment().toDate(),
            ],
            "Last 30 Days": [
              moment().subtract(29, "days").toDate(),
              moment().toDate(),
            ],

            "Last 90 Days": [
              moment().subtract(89, "days").toDate(),
              moment().toDate(),
            ],
          },
        }}
        onEvent={handleEvent}
        onCallback={handleCallback}
      >
        <input
          type="text"
          className="form-control filter-canvas-input"
          value={start1 && end1 && `${start1} - ${end1}`}
          onChange={() => {}}
        />
      </DateRangePicker>
    </div>
  );
};

export default CalenderConfig;
