import React, { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import OrdersList from "./ordersList/ordersList";
import hideIMG from "../../images/Hide.png";
import { getFilteredOrders } from "../../Services/filteredOrders";
import DoughnutChart from "./myChart";
import noData from "../../images/noData.svg";
import ListTable from "../utils/listTable";
import filterIcon from "../../images/filter-icon.svg";
import companyLogo from "../../images/companyLogo.png";
import OrderTableRows from "./orderTableRows";
import downloadIcon from "../../images/download-icon.svg";
import TableSkeleton from "../utils/tableSkeleton";
import { getAllTickets } from "../../Services/getAllTickets";
import Select from "react-select";
import { filterSearchData } from "../utils/filterSearchData";
import { downloadCSVFile } from "../../Services/downloadFile";

import { getDropDownForPO } from "../../Services/allDropDown";
const customStyles = {
  option: (provided, state) => ({
    ...provided,

    cursor: "pointer",
    ":hover": {
      background: "#68a2b9",
      color: "white",
    },
  }),
  control: (base, state) => ({
    ...base,
    height: 48,
    minHeight: 48,
    border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    // This line disable the black border
    boxShadow: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    "&:hover": {
      border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    },
  }),
  // control: (styles, state) => ({
  //   ...styles,
  //   height: 48,
  //   minHeight: 48,
  //   "&:focus": {
  //     border: state.isSelected ? 0 : 0,
  //   },
  //   "&:active": {
  //     border: state.isSelected ? 0 : 0,
  //   },
  // }),
};
const Order = () => {
  const [show, setShow] = useState(false);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activePo, setPO] = useState(undefined);
  const [infoDisplay, setInfoDisplay] = useState("details");
  const [isOpen, setIsOpen] = useState(false);
  const [terminal, setTerminal] = useState(undefined);
  const [product, setProduct] = useState(undefined);
  const [filterPO, setFilterPO] = useState("");
  const [status, setStatus] = useState(undefined);
  const [isFilter, setIsFilter] = useState(false);
  const [open, setOpen] = useState(undefined);
  const [close, setClose] = useState(undefined);
  const [inprogress, setInprogress] = useState(undefined);
  const [dropDown, setDropDown] = useState([]);
  const [isDropDownLoading, setIsDropDownLoading] = useState(true);
  const [poFilteredData, setPoFilteredData] = useState([]);
  const [isPoLoading, setIsPOLoading] = useState(true);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState(undefined);
  let [filtered, setfiltered] = useState(true);
  const selectTerminalRef = useRef();
  const selectProductRef = useRef();

  const getCheckBoxesValue = () => {
    let filterStatus = `${open ? "Open " : ""}${
      inprogress ? "In-Progress " : ""
    }${close ? "Closed" : ""}`;

    return filterStatus;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFilteredOrders(
          terminal,
          undefined,
          product,
          filterPO,
          status,
          undefined,
          undefined
        );

        setResult(data.data.docs);
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };

    fetchData();
    const fetchDropDownData = async () => {
      try {
        const { data } = await getDropDownForPO();
        setDropDown(data);
      } catch (err) {
        console.log(err);
      }
      setIsDropDownLoading(false);
    };

    fetchDropDownData();
  }, [isFilter]);

  const fetchPOData = async (acPo) => {
    try {
      setIsPOLoading(true);
      const data = await getAllTickets(await acPo.PONumber);
      setSearchResult(data.data.docs);
      setPoFilteredData(data.data.docs);
    } catch (err) {
      console.log(err);
    }
    setIsPOLoading(false);
  };

  const filterSearch = (data) => {
    if (data === "") {
      setSearchResult(poFilteredData);
      setfiltered(false);
    } else {
      let filter = filterSearchData(data, searchResult);
      if (filter) {
        setfiltered(true);
        setSearchResult(filter);
      } else {
        setfiltered(false);
      }
    }
  };

  const downloadCSV = async () => {
    toast.promise(
      downloadCSVFile(
        undefined,
        undefined,
        search === "" ? undefined : search,
        activePo.PONumber,
        undefined,
        undefined,
        undefined,
        undefined,
        terminal,
        product,
        status,
        undefined,
        activePo.PONumber
      ),
      {
        loading: "Downloading...",
        success: "Download Success",
        error: "Download Failed",
      }
    );
  };

  return (
    <div className={`${isOpen ? "is-open" : "is-close"}`}>
      <div className="data-list">
        <div className="data-heading">
          <h3>Purchase Orders</h3>

          <button
            className="btn btn-light"
            type="button"
            id="filter-panel"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => {
              setShow(!show);
            }}
          >
            <img src={filterIcon} alt="" />
          </button>
        </div>
        <div className="data-body" style={{ height: "100%" }}>
          <ul className="list" style={{ height: "100%", overflowY: "auto" }}>
            {!isLoading ? (
              result
                .filter((po) => po.POStatus !== "Deleted")
                .map((po, index) => {
                  return (
                    <OrdersList
                      key={index}
                      setActivePo={setPO}
                      poNumber={po.PONumber}
                      productType={po.products[0].product}
                      poStatus={po.POStatus}
                      currentPO={po}
                      activePO={activePo}
                      fetchPOData={fetchPOData}
                    />
                  );
                })
            ) : (
              <ListTable />
            )}
          </ul>
        </div>
      </div>

      <main className="content data-list-content">
        <div className="container-fluid">
          <div className="x-tabs order-tab">
            {activePo ? (
              <>
                <ul className="nav nav-tabs" style={{ top: 0 }}>
                  <button
                    className="toggle-btns"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <img src={hideIMG} alt="hide" />
                  </button>
                  <li
                    className="nav-item"
                    onClick={() => {
                      setInfoDisplay("details");
                    }}
                  >
                    <button
                      className={`nav-link ${
                        infoDisplay === "details" ? "active" : ""
                      }`}
                      aria-current="page"
                    >
                      Details
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      setInfoDisplay("transaction");
                    }}
                  >
                    <button
                      className={`nav-link ${
                        infoDisplay === "transaction" ? "active" : ""
                      }`}
                      onClick={() => {
                        //
                        fetchPOData(activePo);
                      }}
                    >
                      Transactions
                    </button>
                  </li>
                </ul>

                <div className="tab-content">
                  {activePo ? (
                    <div
                      className="tab-pane active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                      style={
                        infoDisplay === "transaction"
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      <div className="row g-0 title-row">
                        <div className="col-md-6">
                          <h1 className="mb-0">{`PO#: ${activePo.PONumber}`}</h1>
                        </div>
                        <div className="col-md-6 text-end"></div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <div className="card card-chart-info">
                            <div className="card-head">
                              <div className="row g-0">
                                <div className="col-md-12">
                                  <h3>Information</h3>
                                </div>
                              </div>
                            </div>

                            <div className="card-body">
                              <div className="row mb-3">
                                <div className="col-sm-6 col-6">
                                  <label className="data-label">Status</label>
                                  <strong className="data-data">
                                    {activePo.POStatus}
                                  </strong>
                                </div>
                                <div className="col-sm-6 col-6">
                                  <label className="data-label">Customer</label>
                                  <strong className="data-data">
                                    {activePo.CustomerName}
                                  </strong>
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col-sm-6 col-6">
                                  <label className="data-label">Terminal</label>
                                  <strong className="data-data">
                                    {activePo.TerminalName}
                                  </strong>
                                </div>
                                <div className="col-sm-6 col-6">
                                  <label className="data-label">Product</label>
                                  <strong className="data-data">
                                    {activePo.product}
                                  </strong>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6 col-6">
                                  <label className="data-label">Quantity</label>
                                  {activePo ? (
                                    <strong className="data-data">
                                      {parseInt(activePo.products[0].amount)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </strong>
                                  ) : (
                                    <strong className="data-data"></strong>
                                  )}
                                </div>
                                <div className="col-sm-6 col-6">
                                  <label className="data-label">Site</label>
                                  <strong className="data-data">
                                    {activePo.SiteName
                                      ? activePo.SiteName
                                      : "-"}
                                  </strong>
                                </div>
                                {/* <div className="col-sm-6 col-6">
                                  <label className="data-label">
                                    Pickup Date
                                  </label>
                                  <strong className="data-data">
                                    {moment(activePo.PickupDate).format(
                                      "yyyy-MM-DD HH:mm"
                                    )}
                                  </strong>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                          <div className="card card-chart">
                            <div className="card-head">
                              <h3>PO Consumption (Tons)</h3>
                            </div>

                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6 col-7 chart-col">
                                  <DoughnutChart
                                    userData={[
                                      activePo.products[0].amount,
                                      activePo.products[0].remaining,
                                    ]}
                                  />
                                </div>
                                <div className="col-md-6 col-5">
                                  <div className="col-md-12 graph-info">
                                    <div className="loaded"></div>
                                    <div>
                                      <label className="data-label">
                                        Loaded
                                      </label>
                                      <strong className="data-data">
                                        {activePo.products[0].dispatched
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-md-12 graph-info">
                                    <div className="remaining"></div>
                                    <div>
                                      <label className="data-label">
                                        Remaining
                                      </label>
                                      <strong className="data-data">
                                        {activePo.products[0].remaining
                                          .toFixed(2)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="col-md-12 graph-info">
                                    <div className="total"></div>
                                    <div>
                                      <label className="data-label">
                                        Total
                                      </label>
                                      <strong className="data-data">
                                        {activePo.products[0].amount
                                          .toFixed(1)
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img src={noData} alt="noData" width="100%" />
                  )}
                  <div
                    className="tab-pane"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    style={
                      infoDisplay === "details"
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <div className="container-fluid">
                      {" "}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="filter-row">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={() => {
                                downloadCSV();
                              }}
                            >
                              <img src={downloadIcon} alt="" /> Download
                              Transactions
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="card-body custom-body-shadow"
                            style={{
                              background: "white",
                            }}
                          >
                            <div
                              className="card-head"
                              style={{ marginBottom: "24px" }}
                            >
                              <div className="row g-0">
                                <div className="col-md-10">
                                  <span
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 600,
                                    }}
                                  >
                                    PO Transactions
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <form>
                                    <input
                                      type="text"
                                      className="form-control search-transaction"
                                      onChange={(e) => {
                                        filterSearch(e.target.value);
                                        setSearch(e.target.value);
                                      }}
                                      placeholder="Search"
                                    />
                                  </form>
                                </div>
                              </div>
                            </div>
                            {!isPoLoading ? (
                              <div className="table-responsive">
                                <table className="table table-style">
                                  <thead>
                                    <tr>
                                      <th scope="col">Ticket #</th>
                                      <th scope="col">Carrier</th>
                                      <th scope="col">Driver</th>
                                      <th scope="col">Truck</th>
                                      <th scope="col">Closed Date</th>
                                      <th scope="col">Weight</th>
                                      <th scope="col">PO#</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!isPoLoading ? (
                                      filtered || search === "" ? (
                                        searchResult.map((po, index) => {
                                          return (
                                            <OrderTableRows
                                              key={index}
                                              poData={po}
                                              imageSrc={downloadIcon}
                                              index={index}
                                            />
                                          );
                                        })
                                      ) : (
                                        <span>{}</span>
                                      )
                                    ) : (
                                      <span>Loading...</span>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <TableSkeleton />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  width: 320,
                  textAlign: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  style={{ height: 80, width: 80 }}
                  src={noData}
                  alt="noData"
                  width="100%"
                />
                <h5>No PO selected</h5>
                <span style={{ fontSize: 14, color: "#58627A" }}>
                  Please Select Your PO
                </span>
              </div>
            )}
          </div>
          <div className="text-end">
            <div className="powerby">
              Powered by
              <img
                src={companyLogo}
                alt="company logo"
                style={{ width: 123, height: 16, marginLeft: 8 }}
              />
            </div>
          </div>
        </div>
      </main>
      <div
        className={`filter-canvas ${show ? "is-open" : "is-close"}`}
        style={!show ? { display: "none" } : { display: "block" }}
      >
        <form>
          <div className="head">
            <h3>Filters</h3>
            <button
              type="button"
              onClick={() => {
                setShow(!show);
              }}
              className="btn btn-close btn-link"
            ></button>
          </div>
          <div className="body">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="terminal" className="form-label">
                    Terminal
                  </label>
                  {!isDropDownLoading ? (
                    <Select
                      className="filter-select-box"
                      styles={customStyles}
                      ref={selectTerminalRef}
                      isClearable={true}
                      onChange={(e) => {
                        setTerminal(e ? e.label : "");
                      }}
                      options={dropDown.terminals.map((key, text) => {
                        return {
                          value: text,
                          label: key.TerminalName,
                        };
                      })}
                    />
                  ) : (
                    <span>Loading...</span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="product" className="form-label">
                    Product
                  </label>
                  {!isDropDownLoading ? (
                    <Select
                      className="filter-select-box"
                      styles={customStyles}
                      isClearable={true}
                      ref={selectProductRef}
                      onChange={(e) => {
                        setProduct(e ? e.label : "");
                      }}
                      options={dropDown.products.map((key, text) => {
                        return {
                          value: text,
                          label: key.product,
                        };
                      })}
                    />
                  ) : (
                    <span>Loading...</span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="po" className="form-label">
                    PO#
                  </label>
                  <input
                    className="form-control filter-canvas-input"
                    type="text"
                    onChange={(e) => {
                      setFilterPO(e.target.value);
                    }}
                    id="po"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input is-canvas-checkbox"
                      type="checkbox"
                      value="Open"
                      id="defaultCheck1"
                      onChange={(e) => {
                        setOpen(e.target.checked);
                      }}
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      <span className="dot3"></span>
                      Open
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input is-canvas-checkbox"
                      type="checkbox"
                      value="In-Progress"
                      id="defaultCheck2"
                      onChange={(e) => {
                        setInprogress(e.target.checked);
                      }}
                    />
                    <label className="form-check-label" htmlFor="defaultCheck2">
                      <span className="dot1"></span>
                      In Progress
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input is-canvas-checkbox"
                      type="checkbox"
                      value="Closed"
                      onChange={(e) => {
                        setClose(e.target.checked);
                      }}
                      id="defaultCheck3"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck3">
                      <span className="dot2"></span>
                      Closed
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="foot">
            <div className="row g-0">
              <div className="col">
                <button
                  type="reset"
                  onClick={() => {
                    selectTerminalRef.current.select.clearValue();
                    selectProductRef.current.select.clearValue();
                    setTerminal(undefined);
                    setProduct(undefined);
                    setFilterPO(undefined);
                    setStatus(undefined);
                    setOpen(undefined);
                    setClose(undefined);
                    setInprogress(undefined);
                    setIsFilter(!isFilter);
                  }}
                  className="btn btn-clear btn-link"
                >
                  Clear
                </button>
              </div>
              <div className="col text-end">
                <button
                  type="button"
                  onClick={() => {
                    setStatus(getCheckBoxesValue());
                    setIsFilter(!isFilter);
                  }}
                  className="btn btn-primary"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Order;
