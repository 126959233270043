import React, { useState, useContext, useEffect } from "react";
import { NavLink as Link, useHistory, useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";

import "react-responsive-modal/styles.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import companyLogo from "../../images/HiCrush.png";
import { AuthContext } from "../../App";
import { UserLogIn } from "../../Services/userLogIn";
import { validateAutoLogin } from "../../Services/autoLoginValidator";
import info from "../../images/info.png";

const LogIn = () => {
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const { logIn } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [autoLogin, setAutoLogin] = useState(false);

  const urlParams = new URLSearchParams(location.search);

  const userToken = urlParams.get("userToken");

  useEffect(() => {
    const validateLogin = async () => {
      if (userToken) {
        try {
          setAutoLogin(true);
          const data = await validateAutoLogin(userToken);
          if (data.status && data.status !== "fail") {
            logIn(data);
            history.push("/");
          } else {
            setAutoLogin(false);
          }
        } catch (err) {
          setAutoLogin(false);
        }
      }
    };

    validateLogin();
  }, [history, logIn, userToken]);

  const checkLogIn = async () => {
    try {
      const data = await UserLogIn(email, password);
      if (data.status && data.status === "fail") {
        setOpen(true);
      } else {
        logIn(data);
        setOpen(false);
        history.push("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="log-banner">
      <div className="overlay">
        <div className="card-centered">
          <div className="card log-card">
            <div className="brand">
              <img className="logo" src={companyLogo} alt="company logo" />

              {autoLogin ? (
                <Loader
                  type="TailSpin"
                  color="#68a2b9"
                  height={30}
                  width={30}
                />
              ) : (
                <form className="row g-0" action="index.html">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        onChange={(e) => {
                          setOpen(false);
                          setEmail(e.target.value);
                        }}
                        style={
                          open
                            ? { border: "1px solid #D94231" }
                            : { borderColor: "#E1E5EE" }
                        }
                        type="email"
                        className="form-control"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type={!showPassword ? "password" : "text"}
                          onChange={(e) => {
                            setOpen(false);
                            setPassword(e.target.value);
                          }}
                          style={
                            open
                              ? { border: "1px solid #D94231" }
                              : { borderColor: "#E1E5EE" }
                          }
                          className="form-control"
                          id="password"
                        />
                        <i
                          className={`custom-eye bi ${
                            showPassword ? "bi-eye" : "bi-eye-slash"
                          }`}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group mb-6">
                      <div className="form-check">
                        <input
                          className="checkbox-password form-check-input"
                          type="checkbox"
                          id="gridCheck"
                        />
                        <label
                          className="form-check-label form-check-remember"
                          htmlFor="gridCheck"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div className="form-group">
                      <Link
                        to="#"
                        onClick={() => {
                          window.location =
                            "https://app.propdispatch.com/forgot";
                        }}
                      >
                        Forgot Password
                      </Link>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="d-grid">
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          checkLogIn();
                          // window.location = window.location.origin + "/";
                        }}
                        className="btn btn-lg btn-primary"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className={`${open ? "show-error" : "hide-error"}`}>
              <span>
                <img src={info} alt="info" style={{ marginRight: 5 }} />
                Email or Password is incorrect!
              </span>
            </div>
            <div className="col-12 text-center mb-4">
              <Link
                className="back-home"
                onClick={() => {
                  window.location = "https://hicrushinc.com/hi-crush/";
                }}
                to="#"
              >
                Back to HCR Home
              </Link>
            </div>

            {/* <div class="col-12 text-center">
          <span className="signup-text">  Dont have an account?  </span><Link className="back-home" to="#">Sign Up</Link>
       </div>
        */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
