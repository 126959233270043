import { getRequestedHeader } from "../components/utils/requestedHeader";

export const UserLogIn = async (email = undefined, password = undefined) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    email: email,
    password: password,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(
    "https://us-central1-hicrush-application.cloudfunctions.net/api/login",
    requestOptions
  );
  const result = await response.json();

  return result;
};

export const verifyUser = async () => {
  const options = getRequestedHeader();
  const response = await fetch(
    "https://us-central1-hicrush-application.cloudfunctions.net/api/check-login",
    options
  );

  const result = await response.json();

  return result;
};
