import React from "react";

const Loaded = ({ icon, loadInfo, numbers, alternative }) => {
  return (
    <div className="col-6 col-md-3 ">
      <div className="card card-info">
        <div className="card-body">
        <div className="row">
        <div className="col-sm-12">
          <img className="card-icon" src={icon} alt={alternative} />
    </div>
          <div className="col-sm-12">
          <h2 className="card-title">{loadInfo}</h2>
          </div>
          </div>
          <div className="col-12">
          <h3 className="card-number">{numbers}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loaded;
