import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getDropDownForTicket = async () => {
  const options = getRequestedHeader();

  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/get-filter-ticket-data`,
    options
  );

  const result = await response.json();

  return result;
};

export const getDropDownForPO = async () => {
  const options = getRequestedHeader();

  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/get-filter-po-data`,
    options
  );

  const result = await response.json();

  return result;
};
