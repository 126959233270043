import React, { useState, useEffect } from "react";
import report_icon from "../../images/report-icon.svg";
import gaudge_icon from "../../images/gaudge-icon.svg";
import Loaded from "./loaded/loaded";
import ContentSkeleton from "../utils/contentLoader";
import { getOrdersOverView } from "../../Services/ordersOverView";
import { getSummary } from "../../Services/currentDayData";
import TableSkeleton from "../utils/tableSkeleton";
import companyLogo from "../../images/companyLogo.png";
import Select from "react-select";
import _ from "lodash";
import TestTable from "./table";
import { getAllTerminals } from "../../Services/allTerminals";
import moment from "moment";

const customStyles = {
  option: (provided, state) => ({
    ...provided,

    background: state.isSelected ? "#68a2b9" : "white",
    cursor: "pointer",
    ":hover": {
      background: "#68a2b9",
      color: "white",
    },
  }),

  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee",
    // This line disable the black border
    boxShadow: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee;",
    "&:hover": {
      border: state.isFocused ? "2px solid #68a2b9" : "2px solid #e1e5ee;",
    },
  }),
};

// const mapingToData = (data, key) => {
//   return data.map(dat => {
//     const obj = {};
//     obj.TerminalName = dat.TerminalName;
//     obj.ProductType = dat.ProductType;
//     obj.PONumber = dat.PONumber;
//     obj.
//   })
// }

const Home = () => {
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSummaryLoading, setIsSummaryLoading] = useState(true);
  const [dailySummary, setDailySummary] = useState([]);
  const [allTerminals, setAllTerminals] = useState([]);
  const [lastFetchDate, setLastFetchDate] = useState([]);
  const [updatedOn, setUpdatedOn] = useState([]);
  const [dataForm, setDataForm] = useState("TONS");
  const [actualData, setActualData] = useState([]);

  let d = [];
  const options = [
    { value: "TONS", label: "Tons" },
    { value: "LBS", label: "Lbs" },
  ];

  const groupTerminals = () => {
    d = _(allTerminals.todaySummary)
      .groupBy("TerminalName")
      .map((group, TerminalName) => ({
        TerminalName,
      }))
      .value();
  };
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const fetchData = await getSummary();
        let data = fetchData.data;
        setDailySummary(data);
        setLastFetchDate(fetchData.date);
        setUpdatedOn(fetchData.updatedOn);
        const fetchTerminals = await getAllTerminals();
        setAllTerminals(fetchTerminals.data);
        // if (data.length === 0) {
        //   setDailySummary({
        //     todayStats: {
        //       loaded: 0,
        //       tonsLoaded: 0,
        //     },
        //     yesterdayStats: {
        //       loaded: 0,
        //       tonsLoaded: 0,
        //     },
        //   });
        // } else {
        //   if (data[0].ClosedOn > data[1].ClosedOn) {
        //     setDailySummary({
        //       todayStats: {
        //         loaded: data[0].loaded,
        //         tonsLoaded: data[0].tonsLoaded,
        //       },
        //       yesterdayStats: {
        //         loaded: data[1].loaded,
        //         tonsLoaded: data[1].tonsLoaded,
        //       },
        //     });
        //   } else {
        //     setDailySummary({
        //       todayStats: {
        //         loaded: data[1].loaded,
        //         tonsLoaded: data[1].tonsLoaded,
        //       },
        //       yesterdayStats: {
        //         loaded: data[0].loaded,
        //         tonsLoaded: data[0].tonsLoaded,
        //       },
        //     });
        //   }
        // }
      } catch (err) {
        console.log(err);
      }
      setIsSummaryLoading(false);
    };

    fetchSummary();

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getOrdersOverView();
        setActualData(data);
        setResult(data);
      } catch (err) {
        console.log(err);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (!isLoading) {
    groupTerminals();
  }

  return (
    <div>
      <main className="content">
        <div className="container-fluid">
          <div className="row g-0 title-row">
            <div className="col-md-6">
              <h1>
                Welcome, {JSON.parse(localStorage.getItem("user")).first_name}{" "}
                {JSON.parse(localStorage.getItem("user")).last_name}
              </h1>
              <p>
                {moment(lastFetchDate).format("MM/DD/yyyy hh:mm A")}&nbsp;
                <span className="dot"></span>{" "}
                <span className="updated-time">
                  {" "}
                  Last Updated {moment(updatedOn).format("hh:mm A")}
                </span>
              </p>
            </div>
          </div>

          <div className="row">
            {!isSummaryLoading ? (
              <>
                <Loaded
                  loadInfo="Loaded Today"
                  alternative="Loaded Today"
                  numbers={dailySummary.today
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  icon={report_icon}
                />
                <Loaded
                  loadInfo="Loaded Yesterday"
                  alternative="Loaded Yesterday"
                  numbers={
                    dailySummary.yesterday
                      ? dailySummary.yesterday
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0
                  }
                  icon={report_icon}
                />
                <Loaded
                  loadInfo="Tons Today"
                  alternative="Loaded Today"
                  numbers={
                    dailySummary.tonsToday
                      ? dailySummary.tonsToday
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0
                  }
                  icon={gaudge_icon}
                />

                <Loaded
                  loadInfo="Tons Yesterday"
                  alternative="Loaded Today"
                  numbers={
                    dailySummary.tonsYesterday
                      ? dailySummary.tonsYesterday
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : 0
                  }
                  icon={gaudge_icon}
                />
              </>
            ) : (
              <ContentSkeleton />
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-table">
                <div className="card-head">
                  <div className="row g-0">
                    <div className="col-6 col-md-10">
                      <h3>Orders Overview</h3>
                    </div>
                    <div className="col-6 col-md-2">
                      <Select
                        className="select-box"
                        isSearchable={false}
                        defaultValue={options[0]}
                        styles={customStyles}
                        value={options.value}
                        onChange={(e) => {
                          // setIsSearch(!isSearch);

                          setDataForm(e.value);
                        }}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body table-section">
                  {!isLoading ? (
                    <TestTable
                      orders={result}
                      dataForm={dataForm}
                      unit={dataForm}
                    />
                  ) : (
                    // <Skeleton count={30} />
                    <TableSkeleton />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end">
          <div className="powerby">
            <span style={{ fontSize: 14 }}>Powered by</span>
            <img
              src={companyLogo}
              alt="company logo"
              style={{ width: "123px", height: "16px", marginLeft: "1%" }}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Home;
