import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Limitation from "./limitation";

const Paginate = ({
  offset,
  setoffset,
  pageCount,
  perPage,
  setLimit,
  limit,
  total,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const changePage = ({ selected }) => {
    setCurrentPage(selected);
    setoffset(parseInt(selected) * limit);
  };
  return (
    <>
      <div className="row pagination-row">
        <div
          className="col-md-3"
          style={{ display: "table", marginTop: "-11px" }}
        >
          <Limitation
            setoffset={setoffset}
            setLimit={setLimit}
            limit={limit}
            setCurrentPage={setCurrentPage}
          />
        </div>

        <div className="col-md-6">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            forcePage={currentPage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
          />
        </div>

        <div className="col-md-3">
          <p className="pagination-items-count">{`${parseInt(offset) + 1}-${
            parseInt(offset) + parseInt(limit) > total
              ? total
              : parseInt(offset) + parseInt(limit)
          } of ${total} tickets`}</p>
        </div>
      </div>
    </>
  );
};

export default Paginate;
