import { getRequestedHeader } from "../components/utils/requestedHeader";
export const getAllTickets = async (poNumber) => {
  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/tickets?${
      poNumber ? "poNumber=" + poNumber : ""
    }&status=Closed`,
    getRequestedHeader()
  );

  const result = await response.json();

  return result;
};
