import axios from "axios";
import { getRequestedHeader } from "../components/utils/requestedHeader";

export const downloadFile = async (customerId, poNumber, ticketNumber) => {
  const { data: response } = await axios.post(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/download-ticket-image`,
    {
      customerId,
      poNumber,
      ticketNumber,
    },
    {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.token)}`,
      },
    }
  );
  return response;
};

export const downloadTicketImage = async (ticketNumber) => {
  const { data: response } = await axios.get(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/download-ticket-image-external/${ticketNumber}`,
    {
      responseType: "blob",
    }
  );

  return response;
};

export const downloadCSVFile = async (
  limit = undefined,
  offset = undefined,
  search = undefined,
  poNumber = undefined,
  ticketNumber = undefined,
  startDate = undefined,
  endDate = undefined,
  customer = undefined,
  terminal = undefined,
  product = undefined,
  ticketStatus = undefined,
  carrier = undefined,
  filename = undefined
) => {
  const response = await fetch(
    `https://us-central1-hicrush-application.cloudfunctions.net/api/download-tickets-csv?${
      search ? "search=" + search : ""
    }&${limit ? "limit=" + limit : ""}&${offset ? "offset=" + offset : ""}&${
      poNumber ? "poNumber=" + poNumber : ""
    }&${ticketStatus ? "ticketStatus=" + ticketStatus : ""}&${
      ticketNumber ? "ticketNumber=" + ticketNumber : ""
    }&${startDate ? "startDate=" + startDate : ""}&${
      endDate ? "endDate=" + endDate : ""
    }&${customer ? "customer=" + customer : ""}&${
      terminal ? "terminal=" + terminal : ""
    }&${product ? "product=" + product : ""}
    &${carrier ? "carrier=" + carrier : ""}`,
    getRequestedHeader()
  );

  let blob = await response.blob();

  var url = window.URL.createObjectURL(blob);
  var a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  // window.open(response, "_blank");
};
