import React from "react";
import { AuthContext } from "../../App";

const Account = () => {
  return (
    <div>
      <div className="profile-list">
        <div className="data-heading">
          <h3>Profile</h3>
        </div>
        <div className="data-body" style={{ height: "100%" }}>
          <ul className="list" style={{ height: "100px", overflowY: "auto" }}>
            <li
              className="list-item active"
              style={{ fontSize: 16, fontWeight: 500 }}
            >
              User Information
            </li>
          </ul>
        </div>
      </div>

      <main className="content profile-list-content">
        <AuthContext.Consumer>
          {({ state }) => {
            const { first_name, last_name, email, phone, address } = state.user;
            return (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-table">
                      <div class="card-head">
                        {/* <div class="row g-0">
                          <div class="col-6 col-md-10">
                            <button type="button" class="btn btn-outline">
                              Upload Photo
                            </button>
                            <button type="button" class="btn remove-image">
                              Remove
                            </button>
                          </div>
                        </div> */}
                      </div>
                      <div class="card-body">
                        <form className="profile-section">
                          <div className="head">
                            <h3>General</h3>
                          </div>
                          <div className="body">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="po" className="form-label">
                                    First Name
                                  </label>
                                  <input
                                    className="form-control profile-input"
                                    type="text"
                                    value={first_name}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="po" className="form-label">
                                    Last Name
                                  </label>
                                  <input
                                    className="form-control profile-input"
                                    type="text"
                                    disabled
                                    value={last_name}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="head">
                                <h3>Contact</h3>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="po" className="form-label">
                                    Email
                                  </label>
                                  <input
                                    className="form-control profile-input"
                                    type="text"
                                    disabled
                                    value={email}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="po" className="form-label">
                                    Phone Number
                                  </label>
                                  <input
                                    className="form-control profile-input"
                                    type="text"
                                    value={phone}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="form-group">
                                  <label
                                    htmlFor="po"
                                    className="form-label address-label"
                                  >
                                    Address
                                  </label>
                                  <input
                                    className="form-control profile-input"
                                    type="text"
                                    value={address}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="foot">
                            <div className="row g-0">
                              <div className="col ">
                                <button
                                  type="button"
                                  className="btn btn-primary save-profile"
                                  disabled
                                >
                                  Save Change
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </AuthContext.Consumer>
      </main>
    </div>
  );
};

export default Account;
<img
  src="https://github.com/mdo.png"
  alt="mdo"
  width="24"
  height="24"
  class="rounded-circle"
></img>;
